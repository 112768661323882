<template lang="pug">
  v-container
    v-toolbar(
      color="yellow darken-4"
      dark
      elevation="0"
    )
      span Edit Feed Detail
      v-spacer
      v-icon mdi-alpha-d-box
    v-container(
      style="max-width: 800px;"
    )
      v-row
        v-col.pb-0(
          cols="12"
        )
          small.primary--text {{ stock.description || null }}
          v-divider
        v-col.pb-0(
          cols="12"
        )
          v-text-field(
            label="Stock Code"
            :value="stockId"
            readonly
            outlined
            dense
          )
        v-col.py-0(
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Size"
            v-model="size"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Cost / Bag"
            v-model="perBag"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Cost / Kilo"
            v-model="perKilo"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Resell / Bag"
            v-model="resellPerBag"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Resell / Kilo"
            v-model="resellPerKilo"
          )
        v-col.py-0(cols="12")
          v-btn(
            block
            elevation="0"
            color="primary"
            dark
            :loading="stockFdDtlPosting"
            @click="updateStockFdDtl"
          )
            span Save
        v-col.pb-0(cols="12")
          v-btn(
            block
            elevation="0"
            color="red"
            dark
            @click="$router.push({ name: 'stocks.feed-details.create' })"
          )
            span Cancel
</template>
<script>
import stockRepository from '@/repositories/stock.repository'
import stockFdDtlRepository from '@/repositories/stock-feed-detail.repository'
import { getVars, postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'

const getStockDelay = searchDelay()
const postFdPrmDelay = searchDelay()

const inputVars = () => ({
  stockId: null,
  size: null,
  perBag: null,
  perKilo: null,
  resellPerBag: null,
  resellPerKilo: null,
  stock: {},
})

export default {
  name: 'EditFeedDetail',
  props: {
    details: {
      type: Object,
      default: () => ({ }),
    },
  },
  created () {
    this.redirect()
    this.getStock()
    this.populateVars(this.details)
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...getVars('stock'),
      ...postVars('stock-fd-dtl'),
      successFdPrm: null,
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
    }),
  },
  watch: {
    details (data) {
      this.redirect()
      this.populateVars(data)
    },
  },
  methods: {
    populateVars (data) {
      Object.keys(data).forEach(key => {
        this[key.snakeToCamelCase()] = data[key]
      })
    },
    getStock () {
      if (this.stockGetting) return
      this.stockGetting = true
      this.resetMessages('successMessage')
      this.resetMessages('errorMessages', [])
      getStockDelay(() => {
        const params = this.getStockIndexParams()
        stockRepository.index(params)
          .then(({ data }) => { this.stockList = data })
          .catch(e => { this.$emit('errors', errorHandler(e)) })
          .then(() => { this.stockGetting = false })
      })
    },
    getStockIndexParams () {
      return {
        noHas: ['feedDetail', 'defaultDetail'].join(','),
      }
    },
    updateStockFdDtl () {
      if (this.stockFdDtlPosting) return
      this.stockFdDtlPosting = true
      this.resetMessages('successMessage', null)
      this.resetMessages('errorMessages', [])
      postFdPrmDelay(() => {
        const data = this.getInputData()
        stockFdDtlRepository.update(this.details.id, data)
          .then(this.stockFdDtlSuccess(this.stockId))
          .catch(e => { this.$emit('errors', errorHandler(e)) })
          .then(() => { this.stockFdDtlPosting = false })
      })
    },
    resetMessages (varName, val) {
      this.$emit('reset', varName, val)
    },
    getInputData () {
      const data = {}
      Object.keys(inputVars()).forEach(e => {
        data[e.camelToSnakeCase()] = this[e]
      })
      return data
    },
    stockFdDtlSuccess (stockId) {
      return ({ data }) => {
        this.$emit('success', `Feeding Detail for ${stockId} has been updated!`)
        this.$router.push({ name: 'stocks.feed-details.create' })
      }
    },
    reset (defaults) {
      Object.keys(defaults).forEach(e => {
        this[e] = defaults[e]
      })
    },
    redirect () {
      if (!this.details.stock_id) this.$router.push({ name: 'stocks.feed-details.create' })
    },
    websocketEvents () {
      this.echo.private('database.updated')
        .listen('StockUpdatedEvent', ({ updated }) => {
          const { original } = updated
          const stockList = this.stockList.map(e => ({ ...e }))
          this.stockList = []
          this.stockList = stockList.objectInsertion(original, 'id', 'id')
        })
        .listen('StockFeedDetailUpdatedEvent', ({ updated }) => {
          const { original } = updated
          this.stockList = this.stockList.filter(item => item.id !== original.stock_id)
        })
    },
    autocompleteFilter (fields) {
      return (item, queryText, itemText) => {
        const filters = fields.map(e => item[e])
        const rxItem = new RegExp(filters.join(), 'i')
        const rxQueryText = new RegExp(queryText, 'i')
        return rxQueryText.test(rxItem)
      }
    },
  },
}
</script>
